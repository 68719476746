import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-become-partner-teacher',
  templateUrl: './become-partner-teacher.component.html',
  styleUrls: ['./become-partner-teacher.component.scss']
})
export class BecomePartnerTeacherComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
