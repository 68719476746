<app-header-style-six></app-header-style-six>

<app-homeeleven-main-banner></app-homeeleven-main-banner>

<app-gym-features></app-gym-features>

<app-why-choose-us-two></app-why-choose-us-two>

<app-homeeleven-courses></app-homeeleven-courses>

<app-funfacts-style-three></app-funfacts-style-three>

<app-gym-feedback></app-gym-feedback>

<app-gym-trainer></app-gym-trainer>

<app-app-download></app-app-download>

<app-homeeleven-blog></app-homeeleven-blog>

<div class="partner-area bg-370920 ptb-100">
    <div class="container">
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>

<app-get-started></app-get-started>