<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg4">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Pricing</li>
            </ul>
            <h2>Transparent Pricing</h2>
        </div>
    </div>
</div>

<div class="pricing-area ptb-100">
    <div class="container">
        <div class="pricing-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Packages</th>
                        <th scope="col">Freelancer</th>
                        <th scope="col">Householder</th>
                        <th scope="col">Business Man</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Control payout timing</td>
                        <td><i class='bx bx-check'></i></td>
                        <td><i class='bx bx-check'></i></td>
                        <td><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td>Transparent payouts</td>
                        <td><i class='bx bx-check'></i></td>
                        <td><i class='bx bx-x'></i></td>
                        <td><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td>Automate evidence submission</td>
                        <td><i class='bx bx-x'></i></td>
                        <td><i class='bx bx-check'></i></td>
                        <td><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td>Collaboration notes</td>
                        <td><i class='bx bx-check'></i></td>
                        <td><i class='bx bx-x'></i></td>
                        <td><i class='bx bx-x'></i></td>
                    </tr>
                    <tr>
                        <td>Deposit tagging</td>
                        <td><i class='bx bx-check'></i></td>
                        <td><i class='bx bx-check'></i></td>
                        <td><i class='bx bx-x'></i></td>
                    </tr>
                    <tr>
                        <td>Technical support over IRC</td>
                        <td><i class='bx bx-check'></i></td>
                        <td><i class='bx bx-check'></i></td>
                        <td><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td>24×7 support</td>
                        <td><i class='bx bx-x'></i></td>
                        <td><i class='bx bx-check'></i></td>
                        <td><i class='bx bx-check'></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>