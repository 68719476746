<app-header-style-one></app-header-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><a href="#">Services</a></li>
            </ul>
            <h2>Services</h2>
        </div>
    </div>
</div>

<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <!-- <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="courses-title">
                        <h2>Service Title</h2>
                        <p>Service text</p>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- service 1 -->
        <div class="row mt-2 mt-md-5">
            <div class="col-lg-4">
                <div class="courses-details-image text-center">
                    <img src="assets/img/courses/courses1.jpg" alt="image" />
                </div>
            </div>
            <div class="col-lg-8">
                <div class="courses-sidebar-syllabus">
                    <h3 class="chapter">Planning</h3>
                    <div class="courses-list">
                        <p>
                            In the planning stage, we are relentless in
                            discovering our client's quirks to portray the
                            finished property as a reflection of their
                            personality, As part of the client brief, various
                            details like culture, overall purpose, special
                            functional features, budget, timelines, are
                            assimilated. we then conduct frequent site visits
                            and survey similar assets
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- service 2 -->
        <div class="row mt-2 mt-md-5">
            <div class="col-lg-4">
                <div class="courses-details-image text-center">
                    <img src="assets/img/courses/courses2.jpg" alt="image" />
                </div>
            </div>
            <div class="col-lg-8">
                <div class="courses-sidebar-syllabus">
                    <h3 class="chapter">Trust Instincts</h3>
                    <div class="courses-list">
                        <p>
                            In the planning stage, we are relentless in
                            discovering our client's quirks to portray the
                            finished property as a reflection of their
                            personality, As part of the client brief, various
                            details like culture, overall purpose, special
                            functional features, budget, timelines, are
                            assimilated. we then conduct frequent site visits
                            and survey similar assets
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- service 3 -->
        <div class="row mt-2 mt-md-5">
            <div class="col-lg-4">
                <div class="courses-details-image text-center">
                    <img src="assets/img/courses/courses3.jpg" alt="image" />
                </div>
            </div>
            <div class="col-lg-8">
                <div class="courses-sidebar-syllabus">
                    <h3 class="chapter">
                        Develop Themes That Define the Users Uniqueness
                    </h3>
                    <div class="courses-list">
                        <p>
                            In the planning stage, we are relentless in
                            discovering our client's quirks to portray the
                            finished property as a reflection of their
                            personality, As part of the client brief, various
                            details like culture, overall purpose, special
                            functional features, budget, timelines, are
                            assimilated. we then conduct frequent site visits
                            and survey similar assets
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- service 4 -->
        <div class="row mt-2 mt-md-5">
            <div class="col-12 col-lg-4">
                <div class="courses-details-image text-center">
                    <img src="assets/img/courses/courses4.jpg" alt="image" />
                </div>
            </div>
            <div class="col-12 col-lg-8">
                <div class="courses-sidebar-syllabus">
                    <h3 class="chapter">A comprehensive study</h3>
                    <div class="courses-list">
                        <p>
                            In the planning stage, we are relentless in
                            discovering our client's quirks to portray the
                            finished property as a reflection of their
                            personality, As part of the client brief, various
                            details like culture, overall purpose, special
                            functional features, budget, timelines, are
                            assimilated. we then conduct frequent site visits
                            and survey similar assets
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- service 5 -->
        <div class="row mt-2 mt-md-5">
            <div class="col-12 col-lg-4">
                <div class="courses-details-image text-center">
                    <img src="assets/img/courses/courses5.jpg" alt="image" />
                </div>
            </div>
            <div class="col-12 col-lg-8">
                <div class="courses-sidebar-syllabus">
                    <h3 class="chapter">
                        Cut Clutter and Strive for Clarity: Less Is More
                    </h3>
                    <div class="courses-list">
                        <p>
                            In the planning stage, we are relentless in
                            discovering our client's quirks to portray the
                            finished property as a reflection of their
                            personality, As part of the client brief, various
                            details like culture, overall purpose, special
                            functional features, budget, timelines, are
                            assimilated. we then conduct frequent site visits
                            and survey similar assets
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- service 6 -->
        <!-- <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img src="assets/img/courses/courses2.jpg" alt="image" />
                </div>
            </div>
            <div class="col-lg-4">
                <div class="courses-sidebar-syllabus">
                    <h3>Dummy text</h3>
                    <span class="chapter">Service 6</span>
                    <h4>Lessons</h4>
                    <div class="courses-list"></div>
                </div>
            </div>
        </div> -->
    </div>
</div>
