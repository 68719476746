<section class="learning-area pt-100 pb-70 bg-dark bg-0b0517">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Education for everyone</span>
            <h2>Affordable Online Courses and Learning Opportunities​</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-learning-box">
                    <div class="icon">
                        <i class="flaticon-lcd"></i>
                    </div>
                    <h3>Go At Your Own Pace</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    <a routerLink="/register" class="link-btn">Register Now</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-learning-box">
                    <div class="icon">
                        <i class="flaticon-shield"></i>
                    </div>
                    <h3>Learn From Expert</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    <a routerLink="/register" class="link-btn">Register Now</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-learning-box">
                    <div class="icon">
                        <i class="flaticon-worldwide"></i>
                    </div>
                    <h3>Learn From Anywhere</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    <a routerLink="/register" class="link-btn">Register Now</a>
                </div>
            </div>
        </div>
    </div>
</section>