import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homefive-main-banner',
  templateUrl: './homefive-main-banner.component.html',
  styleUrls: ['./homefive-main-banner.component.scss']
})
export class HomefiveMainBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
