<section class="online-area pt-100 bg-dark">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="online-image">
                    <img src="assets/img/woman-smile.png" alt="image">
                    <div class="gym-shape1"><img src="assets/img/gym-shape1.png" alt="image"></div>
                    <div class="gym-shape2"><img src="assets/img/gym-shape2.png" alt="image"></div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="online-content">
                    <span class="sub-title">Start Your Online Life Coaching Business!</span>
                    <h2>Start Your Online Life Coaching Business!</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/register" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Get Started Now</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>