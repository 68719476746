<div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">OUR LEADERS</span>
            <h2>Team of Instructors</h2>
            <a routerLink="/team-1" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">Meet All Instructor </span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-team-card mb-30">
                    <img src="assets/img/motivation-course/team/team-1.jpg" alt="image">
                    <div class="team-content">
                        <h3>Alex Maxwel</h3>
                        <span>Founder & CEO</span>
                        <div class="social-btn">
                            <span><i class='bx bxs-share-alt'></i></span>
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/envato" target="_blank">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" target="_blank">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class='bx bxl-instagram' ></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-team-card mb-30">
                    <img src="assets/img/motivation-course/team/team-2.jpg" alt="image">
                    <div class="team-content">
                        <h3>Sarah Taylor</h3>
                        <span>UX/UI Designer</span>
                        <div class="social-btn">
                            <span><i class='bx bxs-share-alt'></i></span>
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/envato" target="_blank">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" target="_blank">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class='bx bxl-instagram' ></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-team-card mb-30">
                    <img src="assets/img/motivation-course/team/team-3.jpg" alt="image">
                    <div class="team-content">
                        <h3>Lee Munroe</h3>
                        <span>Web Developer</span>
                        <div class="social-btn">
                            <span><i class='bx bxs-share-alt'></i></span>
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/envato" target="_blank">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" target="_blank">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class='bx bxl-instagram' ></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>