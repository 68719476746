<section class="app-area bg-dark">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="app-content">
                    <span class="sub-title">Get Start At Everywhere</span>
                    <h2>Ednuv App <br>More Easier Your Life</h2>
                    <p>We understand better that online-based learning can make a significant change to reach students from all over the world! Giving options to learn better always can offer the best outcomes!</p>
                    <a routerLink="/register" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Get Started Now</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="app-image">
                    <img src="assets/img/app.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="overlay"></div>
</section>