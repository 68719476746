<div class="how-it-works-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>History</h2>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <span class="">2016</span>
                    </div>
                    <p>
                        Launched our dignified venture with the zeal of growing
                        and passionately following the ardor to recreate
                        captivating indoor and outdoor spaces.....
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <span>2018</span>
                    </div>
                    <p>
                        We started to excel with new small ventures of room
                        development ideas, partition ideas etc. A phase of
                        selfanalyzation and alteration.....
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <span>2021</span>
                    </div>
                    <p>
                        Downthe lane withbeholding gratitude and experience we
                        starting tie ups and there was no lookingback......
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <span>2023</span>
                    </div>
                    <p>
                        Today we are here to serve and guide, to make your dream
                        space come to reality. Hoping you can rely and confide
                        in us with your aspirations....
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
