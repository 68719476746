<div class="container">
    <div class="row">
        <div class="col-lg-5 col-md-12">
            <div class="section-title text-start">
                <h2>Our Philosophy</h2>
                <img src="../../../../assets/img/philosophy.svg" alt="" />
            </div>
        </div>
        <div class="col-lg-7 col-md-12 mt-3 mt-md-5">
            <div class="story-content">
                <h3>LONG TERM OUTLOOK</h3>
                <p>
                    We keep our clients manage land as an asset that increases
                    in value over many years.
                </p>
                <h3>INTERDISCIPLINARY PRACTICE</h3>
                <p>
                    We combine planning, architecture, landscape architecture
                    and interior design into a single profession.
                </p>
                <h3>MARKET DRIVEN SOLUTIONS</h3>
                <p>
                    We create long lasting environment that have a competing
                    edge in the marketplace.
                </p>
                <h3>ENVIRONMENTALLY RESPONSIVE DEVELOPMENT</h3>
                <p>
                    We are leaders in identifying creative and successful ways
                    to combine resource conservation and development.
                </p>
            </div>
        </div>
    </div>
</div>
