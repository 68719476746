import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homeeight-main-banner',
  templateUrl: './homeeight-main-banner.component.html',
  styleUrls: ['./homeeight-main-banner.component.scss']
})
export class HomeeightMainBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
