import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instructors-page-three',
  templateUrl: './instructors-page-three.component.html',
  styleUrls: ['./instructors-page-three.component.scss']
})
export class InstructorsPageThreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
