<section class="funfacts-and-feedback-area bg-f8e8e9 ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-slides-content">
                    <span class="sub-title">Distance learning</span>
                    <h2>Flexible Study at Your Own Pace, According to Your Own Needs</h2>
                    <p>With the Raque, you can study whenever and wherever you choose. We have students in over 175 countries and a global reputation as a pioneer in the field of flexible learning. Our teaching also means, if you travel often or need to relocate, you can continue to study wherever you go.</p>
                    <div class="feedback-slides-two">
                        <owl-carousel-o [options]="feedbackSlidesTwo">
                            <ng-template carouselSlide>
                                <div class="single-feedback-slides-item">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur rr adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                    <div class="client-info d-flex align-items-center">
                                        <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                        <div class="title">
                                            <h3>John Smith</h3>
                                            <span>Python Developer</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-feedback-slides-item">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur rr adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                    <div class="client-info d-flex align-items-center">
                                        <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                                        <div class="title">
                                            <h3>Sarah Taylor</h3>
                                            <span>PHP Developer</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-feedback-slides-item">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur rr adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                    <div class="client-info d-flex align-items-center">
                                        <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                        <div class="title">
                                            <h3>David Warner</h3>
                                            <span>QA Developer</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                    <div class="feedback-info">
                        <p>Not a member yet?​ <a routerLink="/register">Register now</a></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="funfacts-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="1926">00</span></h3>
                                <p>Finished Sessions</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="3279">00</span></h3>
                                <p>Enrolled Learners</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="250">00</span></h3>
                                <p>Online Instructors</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="100">00</span>%</h3>
                                <p>Satisfaction Rate</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="business-shape6"><img src="assets/img/business-coaching/business-shape5.png" alt="image"></div>
</section>