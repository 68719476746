<section class="courses-area pb-70" id="services">
    <div class="container">
        <ngx-tabset>
            <ngx-tab>
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a class="d-block" routerLink="/services"
                                    ><img
                                        src="assets/img/courses/courses1.jpg"
                                        alt="image"
                                    />
                                    <div class="courses-tag">
                                        <a class="d-block">Service 1</a>
                                    </div></a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a class="d-block" routerLink="/services"
                                    ><img
                                        src="assets/img/courses/courses2.jpg"
                                        alt="image"
                                    />
                                    <div class="courses-tag">
                                        <a class="d-block">Service 2</a>
                                    </div></a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a class="d-block" routerLink="/services"
                                    ><img
                                        src="assets/img/courses/courses3.jpg"
                                        alt="image"
                                    />
                                    <div class="courses-tag">
                                        <a class="d-block">Service 3</a>
                                    </div></a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a class="d-block" routerLink="/services"
                                    ><img
                                        src="assets/img/courses/courses7.jpg"
                                        alt="image"
                                    />
                                    <div class="courses-tag">
                                        <a class="d-block">Service 4</a>
                                    </div></a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a class="d-block" routerLink="/services"
                                    ><img
                                        src="assets/img/courses/courses5.jpg"
                                        alt="image"
                                    />
                                    <div class="courses-tag">
                                        <a class="d-block">Service 5</a>
                                    </div></a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a class="d-block" routerLink="/services"
                                    ><img
                                        src="assets/img/courses/courses6.jpg"
                                        alt="image"
                                    />
                                    <div class="courses-tag">
                                        <a class="d-block">Service 6</a>
                                    </div></a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-tab>
        </ngx-tabset>
    </div>
</section>
