<section class="mission-area ptb-100">
    <div class="container">
        <div class="mission-content">
            <div class="section-title text-start">
                <!-- <span class="sub-title"></span> -->
                <h2 class="sub-title">OUR PHILOSOPHY</h2>
            </div>
            <div class="mission-slides">
                <owl-carousel-o [options]="missionSlides">
                    <ng-template carouselSlide>
                        <h3>LONG TERM OUTLOOK</h3>
                        <p>
                            We keep our clients manage land as an asset that
                            increases in value over many years.
                        </p>

                        <!-- <a routerLink="/" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a> -->
                    </ng-template>
                    <ng-template carouselSlide>
                        <h3>INTERDISCIPLINARY PRACTICE</h3>
                        <p>
                            We combine planning, architecture, landscape
                            architecture and interior design into a single
                            profession.
                        </p>

                        <!-- <a routerLink="/" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a> -->
                    </ng-template>
                    <ng-template carouselSlide>
                        <h3>MARKET DRIVEN SOLUTIONS</h3>
                        <p>
                            We create long lasting environment that have a
                            competing edge in the marketplace.
                        </p>

                        <!-- <a routerLink="/" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a> -->
                    </ng-template>
                    <ng-template carouselSlide>
                        <h3>ENVIRONMENTALLY RESPONSIVE DEVELOPMENT</h3>
                        <p>
                            We are leaders in identifying creative and
                            successful ways to combine resource conservation and
                            development.
                        </p>

                        <!-- <a routerLink="/" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a> -->
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
