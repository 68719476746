<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class="bx bx-map"></i>
                            <a href="#" target="_blank"
                                >C1/213, JANAK PURI, NEW DELHI-110058</a
                            >
                        </li>
                        <li>
                            <i class="bx bx-phone-call"></i>
                            <a href="tel:+91-9910949119">91-9910949119</a>
                        </li>
                        <li>
                            <i class="bx bx-envelope"></i>
                            <a
                                href="mailto:rishamarwahrm@gmail.com
                            "
                                >rishamarwahrm@gmail.com
                            </a>
                        </li>
                        <li>
                            <i class="bx bx-lock-open"></i>
                            <a routerLink="/"
                                >Monday - Friday: By Appointment Only</a
                            >
                        </li>
                        <li>
                            <i class="bx bx-lock"></i
                            ><a routerLink="/">Saturday - Sunday: Closed</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li>
                            <a href="#" class="d-block" target="_blank"
                                ><i class="bx bxl-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"
                                ><i class="bx bxl-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"
                                ><i class="bx bxl-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"
                                ><i class="bx bxl-linkedin"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"
                                ><i class="bx bxl-pinterest-alt"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Hours</h3>
                    <ul class="support-link">
                        <li>
                            <a routerLink="/">Mon 10:00 am – 06:00 pm</a>
                        </li>
                        <li><a routerLink="/">Tue 10:00 am – 06:00 pm</a></li>
                        <li><a routerLink="/">Wed 10:00 am – 06:00 pm</a></li>
                        <li><a routerLink="/">Thu 10:00 am – 06:00 pm</a></li>
                        <li><a routerLink="/">Fri 10:00 am – 06:00 pm</a></li>
                        <li><a routerLink="/">Sat Closed</a></li>
                        <li><a routerLink="/">Sun Closed</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Useful Link</h3>
                    <ul class="useful-link">
                        <!-- <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/">About</a></li>
                        <li><a routerLink="/">WP Development</a></li>
                        <li><a routerLink="/">App</a></li>
                        <li><a routerLink="/">Whitepaper</a></li>
                        <li><a routerLink="/">Web Development</a></li> -->
                        <li>
                            <a
                                routerLink="/"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Home
                            </a>
                        </li>
                        <li>
                            <a
                                routerLink="/about-us"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >About
                            </a>
                        </li>
                        <li>
                            <a
                                routerLink="/our-philosophy"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Our Philosophy
                            </a>
                        </li>
                        <li>
                            <a
                                routerLink="/events"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Services
                            </a>
                        </li>
                        <li>
                            <a
                                routerLink="/portfolio"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Portfolio
                            </a>
                        </li>
                        <li>
                            <a
                                routerLink="/gallery"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Art
                            </a>
                        </li>
                        <li>
                            <a
                                routerLink="/contact"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Contact</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>
                    <div class="newsletter-box">
                        <p>
                            To get the latest news and latest updates from us.
                        </p>
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input
                                type="email"
                                class="input-newsletter"
                                placeholder="Enter your email"
                                name="EMAIL"
                            />
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <!-- <a routerLink="/" class="d-inline-block"><img src="assets/img/logo.png" alt="image"></a> -->
                <a routerLink="/" class="logo-text">Avant Garde Interior</a>
            </div>
            <p>
                Copyright © 2022
                <a routerLink="/">AVANT-GARDE INTERIORS</a>
                - All Rights Reserved.
            </p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
