<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img
                        src="assets/img/about/about.webp"
                        class="shadow"
                        alt="image"
                    />
                    <!-- <img src="assets/img/about/about2.jpg" class="shadow" alt="image"> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h4>
                        OPULENCE, ELEGANCE, AND COMFORT SHOULD UNDERLIE EVERY
                        INTERIOR DESIGN
                    </h4>
                    <p>
                        We believe that the best spaces are truly individual and
                        a reflection of you or your business. We strive to
                        create thoughtful interiors that delight and inspire you
                        on a daily basis, and we scour the globe to find you the
                        most unique and beautiful pieces to enjoy for a
                        lifetime. We values function, comfort, and beauty which
                        we achieve through classic design practices and an
                        artistic approach to composition. Beginning with
                        research and inspiration we move through the stages of
                        design to find you the most efficient, functional and
                        elegant solution while adhering to the practical
                        measures such as budget, accessibility and timeline.
                        With an eye for color and scale, we specialize in
                        curating distinctive spaces, combining antique and
                        modern elements of style in a way that's at once
                        contemporary and timeless.
                    </p>
                    <a routerLink="/about-us" class="default-btn"
                        ><i class="bx bx-move-horizontal icon-arrow before"></i
                        ><span class="label">View More</span
                        ><i class="bx bx-move-horizontal icon-arrow after"></i
                    ></a>
                </div>
            </div>
        </div>
    </div>
</div>
