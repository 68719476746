<app-header-style-one></app-header-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Portfolio</li>
            </ul>
            <h2>Portfolio</h2>
        </div>
    </div>
</div>

<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a class="d-block">
                            <img src="assets/img/blog/intr1.webp" alt="image" />
                        </a>
                        <!-- <div class="tag">
                            <a routerLink="/blog-style-1">Learning</a>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a class="d-block">
                            <img src="assets/img/blog/intr2.webp" alt="image" />
                        </a>
                        <!-- <div class="tag">
                            <a routerLink="/blog-style-1">Education</a>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a class="d-block">
                            <img src="assets/img/blog/intr3.webp" alt="image" />
                        </a>
                        <!-- <div class="tag">
                            <a routerLink="/blog-style-1">Management</a>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a class="d-block">
                            <img src="assets/img/blog/intr4.webp" alt="image" />
                        </a>
                        <!-- <div class="tag">
                            <a routerLink="/blog-style-1">Ideas</a>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a class="d-block">
                            <img src="assets/img/blog/intr5.webp" alt="image" />
                        </a>
                        <!-- <div class="tag">
                            <a routerLink="/blog-style-1">Workforce</a>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a class="d-block">
                            <img src="assets/img/blog/intr6.webp" alt="image" />
                        </a>
                        <!-- <div class="tag">
                            <a routerLink="/blog-style-1">Education</a>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a class="d-block">
                            <img src="assets/img/blog/intr7.webp" alt="image" />
                        </a>
                        <!-- <div class="tag">
                            <a routerLink="/blog-style-1">Learning</a>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a class="d-block">
                            <img src="assets/img/blog/intr8.webp" alt="image" />
                        </a>
                        <!-- <div class="tag">
                            <a routerLink="/blog-style-1">Education</a>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a class="d-block">
                            <img src="assets/img/blog/intr9.webp" alt="image" />
                        </a>
                        <!-- <div class="tag">
                            <a routerLink="/blog-style-1">Management</a>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <span class="page-numbers current" aria-current="page"
                        >1</span
                    >
                    <a routerLink="/blog-style-1" class="page-numbers">2</a>
                    <a routerLink="/blog-style-1" class="page-numbers">3</a>
                    <a routerLink="/blog-style-1" class="page-numbers">4</a>
                    <a routerLink="/blog-style-1" class="page-numbers">5</a>
                    <a routerLink="/blog-style-1" class="next page-numbers"
                        ><i class="bx bx-chevron-right"></i
                    ></a>
                </div>
            </div> -->
        </div>
    </div>
</div>
