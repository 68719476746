import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
    selector: "app-contact-page",
    templateUrl: "./contact-page.component.html",
    styleUrls: ["./contact-page.component.scss"],
})
export class ContactPageComponent implements OnInit {
    mapUrl: string =
        "https://www.google.com/maps/place/Ahmedabad,+Gujarat/@23.0431544,72.5323655,12.85z/data=!4m15!1m8!3m7!1s0x395e848aba5bd449:0x4fcedd11614f6516!2sAhmedabad,+Gujarat!3b1!8m2!3d23.022505!4d72.5713621!16zL20vMDFkODhj!3m5!1s0x395e848aba5bd449:0x4fcedd11614f6516!8m2!3d23.022505!4d72.5713621!16zL20vMDFkODhj?entry=ttu";

    // Sanitized URL
    sanitizedMapUrl: SafeResourceUrl;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit(): void {
        this.sanitizedMapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.mapUrl
        );
    }

    submit(form) {
        var name = form.name;
        console.log(name);

        var email = form.email;
        console.log(email);

        var number = form.number;
        console.log(number);

        var subject = form.subject;
        console.log(subject);

        var message = form.message;
        console.log(message);
    }
}
