<section class="why-choose-us-area bg-e6fbff">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-image">
                    <img src="assets/img/why-choose-us.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content">
                    <div class="content">
                        <span class="sub-title">Why Choose Us</span>
                        <h2>Amazing Courses to Learn Language Better</h2>
                        <p>We understand better that online-based learning can make a significant change to reach students from all over the world! Giving options to learn better always can offer the best outcomes!</p>
                        <ul class="features-list">
                            <li><span><i class="flaticon-self-growth"></i> Skiled Teachers</span></li>
                            <li><span><i class="flaticon-clock"></i> Afordable Courses</span></li>
                            <li><span><i class="flaticon-ebook"></i> Efficient & Flexible</span></li>
                            <li><span><i class="flaticon-factory"></i> Lifetime Access</span></li>
                        </ul>
                        <a routerLink="/register" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Join For Free</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="lang-shape2"><img src="assets/img/lang-shape2.png" alt="image"></div>
</section>