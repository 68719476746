<div class="banner-section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-content">
                    <span class="sub-title">The Future</span>
                    <h1>Online Courses for those who learn differently</h1>
                    <p>Education can be passport to the future if it does believe.</p>
                    <div class="btn-box">
                        <a routerLink="/contact.html" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Get Started</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                        <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" target="_blank" class="video-btn"><span><i class='bx bx-play-circle'></i></span> Watch Video</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divider"></div>
</div>