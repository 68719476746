import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homefour-main-banner',
  templateUrl: './homefour-main-banner.component.html',
  styleUrls: ['./homefour-main-banner.component.scss']
})
export class HomefourMainBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
