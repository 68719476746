import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-shipping-address-page',
  templateUrl: './edit-shipping-address-page.component.html',
  styleUrls: ['./edit-shipping-address-page.component.scss']
})
export class EditShippingAddressPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
