<section class="events-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Events</span>
            <h2>Our Upcoming Events</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg1">
                                <img src="assets/img/business-coaching/events-img1.jpg" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span>Wed, 03 June, 2022</span>
                                </div>
                                <h3><a routerLink="/single-events">Music Conference</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                <span class="location"><i class="bx bx-map"></i>Vancover, Canada</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg2">
                                <img src="assets/img/business-coaching/events-img2.jpg" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span>Thu, 04 June, 2022</span>
                                </div>
                                <h3><a routerLink="/single-events">Paper Plates Art</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                <span class="location"><i class="bx bx-map"></i>Sydney, Australia</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg3">
                                <img src="assets/img/business-coaching/events-img3.jpg" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span>Fri, 05 June, 2022</span>
                                </div>
                                <h3><a routerLink="/single-events">Imagination Classes</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                <span class="location"><i class="bx bx-map"></i>Istanbul, Turkey</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg4">
                                <img src="assets/img/business-coaching/events-img4.jpg" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span>Sat, 06 June, 2022</span>
                                </div>
                                <h3><a routerLink="/single-events">Number Matching</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                <span class="location"><i class="bx bx-map"></i>New York, USA</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="business-shape7"><img src="assets/img/business-coaching/business-shape4.png" alt="image"></div>
</section>