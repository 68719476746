<app-header-style-seven></app-header-style-seven>

<app-homefourteen-main-banner></app-homefourteen-main-banner>

<div class="pt-100">
    <app-features-stye-three></app-features-stye-three>
</div>

<app-homethirteen-popular-courses></app-homethirteen-popular-courses>

<app-homefourteen-about></app-homefourteen-about>

<app-funfacts-style-four></app-funfacts-style-four>

<app-free-trial-form></app-free-trial-form>

<app-homethirteen-courses></app-homethirteen-courses>

<div class="testimonials-wrap-area bg-E7F0FD pt-100">
    <app-happy-students-feedback></app-happy-students-feedback>
</div>

<app-free-trial-two></app-free-trial-two>

<div class="overview-connections-area bg-FAF8F8 pb-70">
    <app-make-connections></app-make-connections>
</div>

<app-instructors-style-four></app-instructors-style-four>

<app-start-free-courses></app-start-free-courses>