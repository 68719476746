<header class="header-area p-relative">
    <!-- <div class="top-header top-header-style-four">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <ul class="top-header-contact-info">
                        <li>
                            Call: 
                            <a href="tel:+502464674">+502 464 674</a>
                        </li>
                    </ul>
                    <div class="top-header-social">
                        <span>Follow us:</span>
                        <a href="#" target="_blank"><i class='bx bxl-facebook'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-twitter'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-instagram'></i></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <ul class="top-header-login-register">
                        <li><a routerLink="/login"><i class='bx bx-log-in'></i> Login</a></li>
                        <li><a routerLink="/register"><i class='bx bx-log-in-circle'></i> Register</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->
    <div
        class="navbar-area navbar-style-three"
        ngStickyNav
        stickyClass="sticky-box-shadow"
        ngStickyNav
    >
        <div class="container">
            <nav
                class="navbar navbar-expand-lg navbar-light bg-light"
                [class.active]="classApplied"
            >
                <!-- <a class="navbar-brand" routerLink="/"><img src="assets/img/black-logo.png" alt="logo"></a> -->
                <a routerLink="/" class="navbar-brand logo-text"
                    >Avant Garde Interior</a
                >
                <button
                    class="navbar-toggler"
                    type="button"
                    (click)="toggleClass()"
                >
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div
                    class="collapse navbar-collapse"
                    id="navbarSupportedContent"
                >
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a
                                routerLink="/"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Home
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                routerLink="/about-us"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >About
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                routerLink="/our-philosophy"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Our Philosophy
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                routerLink="/services"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Services
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                routerLink="/portfolio"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Portfolio
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                routerLink="/gallery"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Art
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                routerLink="/contact"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Contact</a
                            >
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>

<div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input
                        type="text"
                        class="input-search"
                        placeholder="Search here..."
                    />
                    <button type="submit">
                        <i class="bx bx-search-alt"></i>
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
