<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Instructors</li>
            </ul>
            <h2>Team of Instructors</h2>
        </div>
    </div>
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img src="assets/img/instructor/instructor1.jpg" alt="images">
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-instructor">Jonkin Jullinor</a></h3>
                        <span>OOP Developer</span>
                        <ul class="social">
                            <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img src="assets/img/instructor/instructor2.jpg" alt="images">
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-instructor">Sarah Taylor</a></h3>
                        <span>Angular Developer</span>
                        <ul class="social">
                            <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img src="assets/img/instructor/instructor3.jpg" alt="images">
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-instructor">David Warner</a></h3>
                        <span>PHP Developer</span>
                        <ul class="social">
                            <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img src="assets/img/instructor/instructor4.jpg" alt="images">
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-instructor">Lina D'Souja</a></h3>
                        <span>Laravel Developer</span>
                        <ul class="social">
                            <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img src="assets/img/instructor/instructor5.jpg" alt="images">
                    </div>

                    <div class="member-content">
                        <h3><a routerLink="/single-instructor">Steven Smith</a></h3>
                        <span>Application Developer</span>
                        <ul class="social">
                            <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img src="assets/img/instructor/instructor6.jpg" alt="images">
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-instructor">Sakira Lucy</a></h3>
                        <span>Software Developer</span>
                        <ul class="social">
                            <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>