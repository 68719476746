<app-header-style-one></app-header-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><a href="#">Services</a></li>
            </ul>
            <h2>Services</h2>
        </div>
    </div>
</div>

<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="courses-title">
                        <h2>Service 1</h2>
                        <p>Service 1 text</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img src="assets/img/courses/courses1.jpg" alt="image" />
                </div>
            </div>
            <div class="col-lg-4">
                <div class="courses-sidebar-syllabus">
                    <h3>Dummy text</h3>
                    <span class="chapter">Service 1</span>
                    <h4>Lessons</h4>
                    <div class="courses-list">
                        <ul>
                            <li>Introduction</li>
                            <li>
                                <a routerLink="/single-courses">
                                    <span class="number">1.</span> Secret 1:
                                    Sell The Problem, Not The Solution (14:37)
                                    <span class="free-lesson">Free</span>
                                </a>
                            </li>
                            <li>
                                Quiz - Secret 1: Sell The Problem, Not The
                                Solution
                            </li>
                            <li>
                                <span class="number">2.</span> Secret 2: Think
                                Like a Magazine, Not a Salesman (4:30)
                            </li>
                            <li>
                                Quiz - Secret 2: Think Like a Magazine, Not a
                                Salesman
                            </li>
                            <li>
                                <span class="number">3.</span> Secret 3: Content
                                Is King, but Consistency Is Queen (3:56)
                            </li>
                            <li>
                                Quiz - Secret 3: Content Is King, but
                                Consistency Is Queen
                            </li>
                            <li>
                                <span class="number">4.</span> Secret 4: Punch
                                Above Your Weight Class (11:00)
                            </li>
                            <li>
                                <span class="number">5.</span> Secret 5:
                                Ethically Steal Your Competitors' Customers
                                (8:12)
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
