<div class="courses-categories-slides">
    <owl-carousel-o [options]="categoriesSlides">
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class="bx bx-code-alt"></i>
                </div>
                <h3>Service 1</h3>
                <!-- <span>60 Courses</span> -->
                <!-- <a routerLink="/" class="link-btn"></a> -->
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class="bx bx-camera"></i>
                </div>
                <h3>Service 2</h3>
                <!-- <span>60 Courses</span> -->
                <!-- <a routerLink="/" class="link-btn"></a> -->
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class="bx bx-layer"></i>
                </div>
                <h3>Service 3</h3>
                <!-- <span>60 Courses</span> -->
                <!-- <a routerLink="/" class="link-btn"></a> -->
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class="bx bxs-flag-checkered"></i>
                </div>
                <h3>Service 4</h3>
                <!-- <span>60 Courses</span> -->
                <!-- <a routerLink="/" class="link-btn"></a> -->
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class="bx bx-health"></i>
                </div>
                <h3>Service 5</h3>
                <!-- <span>60 Courses</span> -->
                <!-- <a routerLink="/" class="link-btn"></a> -->
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class="bx bx-line-chart"></i>
                </div>
                <h3>Service 6</h3>
                <!-- <span>60 Courses</span> -->
                <!-- <a routerLink="/" class="link-btn"></a> -->
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
