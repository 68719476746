<section class="feedback-area bg-f8fafb ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-image">
                    <img src="assets/img/health-coaching/feedback-img.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="feedback-content">
                    <p class="playfair-display">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <div class="client-info">
                        <div class="d-flex align-items-center">
                            <img src="assets/img/user1.jpg" alt="image">
                            <div class="title">
                                <h3>John Smith</h3>
                                <span>Python Developer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>