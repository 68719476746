<div class="health-coaching-bg-image">

    <!-- Main Banner Area -->
    <section class="health-coaching-banner">
        <div class="container-fluid">
            <div class="health-coaching-banner-slides">
                <owl-carousel-o [options]="healthCoachingBannerSlides">
                    <ng-template carouselSlide>
                        <div class="single-banner-item bg-1">
                            <h1 class="playfair-display">Welcome, <br>I am Angela,<br> your nutritionist</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <a routerLink="/register" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Join For Free</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="single-banner-item bg-2">
                            <h1 class="playfair-display">Welcome, <br>I am Angela,<br> your nutritionist</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <a routerLink="/register" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Join For Free</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </section>

    <!-- Featured Area -->
    <section class="featured-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2 class="playfair-display">Inspring you to live a healthier lifestyle</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-featured-box">
                        <div class="icon">
                            <i class="flaticon-diet"></i>
                        </div>
                        <h3 class="playfair-display">Nutrition Strategies</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <a routerLink="/" class="link-btn">Start Now</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-featured-box">
                        <div class="icon">
                            <i class="flaticon-vitamin-c"></i>
                        </div>
                        <h3 class="playfair-display">Personal Program</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <a routerLink="/" class="link-btn">Start Now</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-featured-box">
                        <div class="icon">
                            <i class="flaticon-heart-rate-monitor"></i>
                        </div>
                        <h3 class="playfair-display">Find Your Balance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <a routerLink="/" class="link-btn">Start Now</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>