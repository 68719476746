import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-make-connections',
  templateUrl: './make-connections.component.html',
  styleUrls: ['./make-connections.component.scss']
})
export class MakeConnectionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
