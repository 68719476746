<div class="container">
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <div class="faq-video">
                <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                    <a
                        data-lg-size="1280-720"
                        data-src="https://www.youtube.com/watch?v=68mTG-fxzX4"
                        data-sub-html="<p>Ednuv Video</p>"
                    >
                        <img src="assets/img/business-coaching/faq.jpg" alt="image">
                        <div class="video-btn">
                            <i class='bx bx-play'></i>
                        </div>
                    </a>
                </lightgallery>
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="faq-accordion faq-accordion-style-two">
                <accordion>
                    <accordion-group heading="What are the different types of undergraduate degrees?" [isOpened]="true">
                        <p>Associate: a two-year program that either leads to a specific vocation or transitions to a bachelor program. Bachelor: a four or five-year program where students earn credits in a wide variety of courses.</p>
                    </accordion-group>
                    <accordion-group heading="What are the different types of graduate degrees?">
                        <p>Associate: a two-year program that either leads to a specific vocation or transitions to a bachelor program. Bachelor: a four or five-year program where students earn credits in a wide variety of courses.</p>
                    </accordion-group>
                    <accordion-group heading="Can you work while studying in the United States?">
                        <p>Associate: a two-year program that either leads to a specific vocation or transitions to a bachelor program. Bachelor: a four or five-year program where students earn credits in a wide variety of courses.</p>
                    </accordion-group>
                    <accordion-group heading="What is distance education?">
                        <p>Associate: a two-year program that either leads to a specific vocation or transitions to a bachelor program. Bachelor: a four or five-year program where students earn credits in a wide variety of courses.</p>
                    </accordion-group>
                </accordion>
            </div>
        </div>
    </div>
</div>