<!-- <div class="home-slides">
    <owl-carousel-o [options]="homeSlides">
        <ng-template carouselSlide>
            <div class="main-banner item-bg1">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">
                                <h2>LONG TERM OUTLOOK</h2>
                                <p>
                                    We keep our clients manage land as an asset
                                    that increases in value over many years.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg2">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">
                                <h2>INTERDISCIPLINARY PRACTICE</h2>
                                <p>
                                    We combine planning, architecture, landscape
                                    architecture and interior design into a
                                    single profession.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg3">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">
                                <h2>MARKET DRIVEN SOLUTIONS</h2>
                                <p>
                                    We create long lasting environment that have
                                    a competing edge in the marketplace.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg4">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">
                                <h2>ENVIRONMENTALLY RESPONSIVE DEVELOPMENT</h2>
                                <p>
                                    We are leaders in identifying creative and
                                    successful ways to combine resource
                                    conservation and development.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div> -->
<div class="home-slides" id="home_main">
    <owl-carousel-o [options]="homeSlides">
        <ng-template carouselSlide>
            <div class="main-banner item-bg1">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">
                                <span class="sub-title"
                                    >Weapon is Education</span
                                >
                                <h2>LONG TERM OUTLOOK</h2>
                                <p>
                                    We keep our clients manage land as an asset
                                    that increases in value over many years.
                                </p>
                                <!-- <div class="btn-box">
                                    <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                    <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg2">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">
                                <span class="sub-title">The Future</span>
                                <h2>INTERDISCIPLINARY PRACTICE</h2>
                                <p>
                                    We combine planning, architecture, landscape
                                    architecture and interior design into a
                                    single profession.
                                </p>
                                <!-- <div class="btn-box">
                                    <a routerLink="/courses-2-columns-style-2" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                    <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg3">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">
                                <span class="sub-title"
                                    >Aim for Excellence</span
                                >
                                <h2>MARKET DRIVEN SOLUTIONS</h2>
                                <p>
                                    We create long lasting environment that have
                                    a competing edge in the marketplace.
                                </p>
                                <!-- <div class="btn-box">
                                    <a routerLink="/courses-2-columns-style-3" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                    <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg4">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">
                                <span class="sub-title"
                                    >Aim for Excellence</span
                                >
                                <h2>ENVIRONMENTALLY RESPONSIVE DEVELOPMENT</h2>
                                <p>
                                    We are leaders in identifying creative and
                                    successful ways to combine resource
                                    conservation and development.
                                </p>
                                <!-- <div class="btn-box">
                                    <a routerLink="/courses-2-columns-style-3" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                    <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
