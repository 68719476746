import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gym-features',
  templateUrl: './gym-features.component.html',
  styleUrls: ['./gym-features.component.scss']
})
export class GymFeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
