<section class="main-banner-wrapper">
    <div class="container">
        <div class="banner-wrapper-content">
            <h1>Take the next step toward your <a href="" class="typewrite" data-period="2000" data-type='[ "Design", "Web", "PHP", "Code" ]'><span class="wrap"></span></a> with Ednuv</h1>
            <p>95% of people learning for professional development report career benefits like getting a promotion, a raise, or starting a new career.</p>
            <form>
                <input type="text" class="input-search" placeholder="What do you want to learn?">
                <button type="button">Search</button>
            </form>
        </div>
    </div>
    <div class="banner-wrapper-image text-center">
        <img src="assets/img/banner-img2.png" alt="image">
    </div>
</section>