<app-header-style-one></app-header-style-one>

<app-hometwo-main-banner></app-hometwo-main-banner>

<app-homeone-about></app-homeone-about>

<app-how-it-works></app-how-it-works>

<div class="courses-categories-area mt-5">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">SERVICES</span>
            <h2>Our Services</h2>
            <a routerLink="/services" class="default-btn"
                ><i class="bx bx-show-alt icon-arrow before"></i
                ><span class="label">View All</span
                ><i class="bx bx-show-alt icon-arrow after"></i
            ></a>
        </div>
        <app-homeone-courses></app-homeone-courses>
    </div>
</div>

<div class="courses-categories-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Social</span>
            <h2>Instagram Feed</h2>
        </div>
    </div>
</div>
