import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page-six',
  templateUrl: './home-page-six.component.html',
  styleUrls: ['./home-page-six.component.scss']
})
export class HomePageSixComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
