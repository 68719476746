import { Component, OnInit } from "@angular/core";
import {
    NgxGalleryAnimation,
    NgxGalleryImage,
    NgxGalleryOptions,
} from "@kolkov/ngx-gallery";
import { Lightbox } from "ngx-lightbox";

@Component({
    selector: "app-gallery-page",
    templateUrl: "./gallery-page.component.html",
    styleUrls: ["./gallery-page.component.scss"],
})
export class GalleryPageComponent implements OnInit {
    ngOnInit(): void {}

    public _album = [];
    constructor(public _lightbox: Lightbox) {
        for (let i = 1; i <= 39; i++) {
            const src = "assets/img/gallery/gall" + i + ".webp";
            const caption = "Image " + i;
            const thumb = "assets/img/gallery/gall" + i + ".webp";
            const album = {
                src: src,
                caption: caption,
                thumb: thumb,
            };
            this._album.push(album);
        }
    }

    open(index: number): void {
        // open lightbox
        this._lightbox.open(this._album, index, {
            fitImageInViewPort: "cover", // or 'contain' based on your preference
            maxWidth: 500, // specify your desired width
            maxHeight: 500, // specify your desired height
            centerVertically: true,
            centerHorizontally: true,
        });
    }

    close(): void {
        // close lightbox programmatically
        this._lightbox.close();
    }
    // galleryOptions: NgxGalleryOptions[];
    // galleryImages: NgxGalleryImage[];

    // constructor() {}

    // ngOnInit() {
    //     this.galleryOptions = [
    //         {
    //             width: "600px",
    //             height: "400px",
    //             thumbnailsColumns: 4,
    //             arrowPrevIcon: "fa fa-chevron-left",
    //             arrowNextIcon: "fa fa-chevron-right",
    //             imageAnimation: NgxGalleryAnimation.Slide,
    //         },
    //         // max-width 800
    //         {
    //             breakpoint: 800,
    //             width: "100%",
    //             height: "600px",
    //             imagePercent: 80,
    //             thumbnailsPercent: 20,
    //             thumbnailsMargin: 20,
    //             thumbnailMargin: 20,
    //         },
    //         // max-width 400
    //         {
    //             breakpoint: 400,
    //             preview: false,
    //         },
    //     ];

    //     this.galleryImages = [
    //         {
    //             small: "https://preview.ibb.co/jrsA6R/img12.jpg",
    //             medium: "https://preview.ibb.co/jrsA6R/img12.jpg",
    //             big: "https://preview.ibb.co/jrsA6R/img12.jpg",
    //         },
    //         {
    //             small: "https://preview.ibb.co/kPE1D6/clouds.jpg",
    //             medium: "https://preview.ibb.co/kPE1D6/clouds.jpg",
    //             big: "https://preview.ibb.co/kPE1D6/clouds.jpg",
    //         },
    //         {
    //             small: "https://preview.ibb.co/mwsA6R/img7.jpg",
    //             medium: "https://preview.ibb.co/mwsA6R/img7.jpg",
    //             big: "https://preview.ibb.co/mwsA6R/img7.jpg",
    //         },
    //         {
    //             small: "https://preview.ibb.co/kZGsLm/img8.jpg",
    //             medium: "https://preview.ibb.co/kZGsLm/img8.jpg",
    //             big: "https://preview.ibb.co/kZGsLm/img8.jpg",
    //         },
    //     ];
    // }
}
