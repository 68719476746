<app-header-style-one></app-header-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img
                        src="assets/img/about/about2.webp"
                        class="shadow"
                        alt="image"
                    />
                    <!-- <img src="assets/img/about/about2.jpg" class="shadow" alt="image"> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h4>
                        OPULENCE, ELEGANCE, AND COMFORT SHOULD UNDERLIE EVERY
                        INTERIOR DESIGN
                    </h4>
                    <p>
                        We believe that the best spaces are truly individual and
                        a reflection of you or your business. We strive to
                        create thoughtful interiors that delight and inspire you
                        on a daily basis, and we scour the globe to find you the
                        most unique and beautiful pieces to enjoy for a
                        lifetime. We values function, comfort, and beauty which
                        we achieve through classic design practices and an
                        artistic approach to composition. Beginning with
                        research and inspiration we move through the stages of
                        design to find you the most efficient, functional and
                        elegant solution while adhering to the practical
                        measures such as budget, accessibility and timeline.
                        With an eye for color and scale, we specialize in
                        curating distinctive spaces, combining antique and
                        modern elements of style in a way that's at once
                        contemporary and timeless.
                    </p>
                    <br />
                    <p>
                        With an eye for color and scale, we specialize in
                        curating distinctive spaces, combining antique and
                        modern elements of style in a way that's at once
                        contemporary and timeless
                    </p>
                </div>
            </div>
        </div>
        <!-- <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>100,000 online courses</h3>
                        <p>
                            Real innovations and a positive customer experience
                            are the heart of successful communication.
                        </p>
                        <ul class="features-list">
                            <li>
                                <i class="bx bx-check"></i> Activate Listening
                            </li>
                            <li><i class="bx bx-check"></i> Brilliant minds</li>
                            <li>
                                <i class="bx bx-check"></i> Better. Best. Wow!
                            </li>
                            <li>
                                <i class="bx bx-check"></i> Branding it better!
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Expert instruction</h3>
                        <p>
                            Real innovations and a positive customer experience
                            are the heart of successful communication.
                        </p>
                        <ul class="features-list">
                            <li>
                                <i class="bx bx-check"></i> Creating. Results.
                            </li>
                            <li><i class="bx bx-check"></i> Expect more</li>
                            <li><i class="bx bx-check"></i> Good thinking</li>
                            <li>
                                <i class="bx bx-check"></i> In real we trust
                            </li>
                        </ul>
                    </div>
                </div>
                <div
                    class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
                >
                    <div class="about-text">
                        <h3>Lifetime access</h3>
                        <p>
                            Real innovations and a positive customer experience
                            are the heart of successful communication.
                        </p>
                        <ul class="features-list">
                            <li>
                                <i class="bx bx-check"></i> Stay real. Always.
                            </li>
                            <li>
                                <i class="bx bx-check"></i> We have you covered
                            </li>
                            <li><i class="bx bx-check"></i> We turn heads</li>
                            <li>
                                <i class="bx bx-check"></i> Your brand, promoted
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>

<!-- <app-our-mission></app-our-mission> -->

<!-- <div class="story-area ptb-100">
    <app-our-story></app-our-story>
</div> -->

<!-- <div class="funfacts-area">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div> -->

<!-- <div class="values-area ptb-100">
    <app-our-values></app-our-values>
</div> -->

<!-- <div class="instructor-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <app-instructors-style-two></app-instructors-style-two>
    </div>
</div> -->

<!-- <div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div> -->

<!-- <div class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What Students Says</h2>
        </div>
        <app-feedback-style-two></app-feedback-style-two>
    </div>
</div> -->

<!-- <app-become-instructor-partner></app-become-instructor-partner> -->
