<div class="motivation-offer-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="motivation-offer-image"></div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="motivation-offer-content">
                    <span class="sub-title">MAKE CONNECTIONS</span>
                    <h3>What We Offer For Growth</h3>
                    <div class="offer-inner-box">
                        <div class="icon">
                            <i class='bx bx-user'></i>
                        </div>
                        <h4>Exclusive Advisor</h4>
                        <p>Nulla quis lorem ut libero malesuada feugiat pellentesque in ipsum id orci porta dapibus donec sollicitudin molestie malesuada quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                    </div>
                    <div class="offer-inner-box">
                        <div class="icon">
                            <i class='bx bx-award'></i>
                        </div>
                        <h4>Reached Your Goals</h4>
                        <p>Nulla quis lorem ut libero malesuada feugiat pellentesque in ipsum id orci porta dapibus donec sollicitudin molestie malesuada quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                    </div>
                    <div class="offer-inner-box">
                        <div class="icon">
                            <i class='bx bxs-drink'></i>
                        </div>
                        <h4>Digital Laboratory</h4>
                        <p>Nulla quis lorem ut libero malesuada feugiat pellentesque in ipsum id orci porta dapibus donec sollicitudin molestie malesuada quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>