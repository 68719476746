<section class="motivation-courses-area pt-100 pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">DISCOVER COURSES</span>
            <h2>Our Popular Online Courses</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="single-motivation-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/motivation-course/courses/courses-4.jpg" alt="image"></a>
                        <div class="price">$120</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Margaret James</span>
                            </div>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">(0 Rating)</div>
                        </div>
                        <div class="courses-box-footer">
                            <ul>
                                <li class="students-number">
                                    <i class='bx bx-user'></i> 4 students
                                </li>
                                <li class="courses-lesson">
                                    <i class='bx bx-book-open'></i> 10 lessons
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="single-motivation-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/motivation-course/courses/courses-5.jpg" alt="image"></a>
                        <div class="price">$199</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Brian Smalley</span>
                            </div>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Edali – Go From Zero to Hero with Node.js</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">(0 Rating)</div>
                        </div>
                        <div class="courses-box-footer">
                            <ul>
                                <li class="students-number">
                                    <i class='bx bx-user'></i> 20 students
                                </li>
                                <li class="courses-lesson">
                                    <i class='bx bx-book-open'></i> 9 lessons
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="single-motivation-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/motivation-course/courses/courses-6.jpg" alt="image"></a>
                        <div class="price">$125</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                                <span>James Wirth</span>
                            </div>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Edali Professional IT Expert Certificate Course</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">(0 Rating)</div>
                        </div>
                        <div class="courses-box-footer">
                            <ul>
                                <li class="students-number">
                                    <i class='bx bx-user'></i> 8 students
                                </li>
                                <li class="courses-lesson">
                                    <i class='bx bx-book-open'></i> 10 lessons
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="single-motivation-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/motivation-course/courses/courses-7.jpg" alt="image"></a>
                        <div class="price">$175</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Phillip Massey</span>
                            </div>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Certified JavaScript with Free Project Course</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">(0 Rating)</div>
                        </div>
                        <div class="courses-box-footer">
                            <ul>
                                <li class="students-number">
                                    <i class='bx bx-user'></i> 9 students
                                </li>
                                <li class="courses-lesson">
                                    <i class='bx bx-book-open'></i> 9 lessons
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="courses-info-wrap">
                    <p>Enjoy the top notch learning methods and achieve next level skills! You are the creator of your own career & we will guide you through that. <a routerLink="/register">Register Free Now!</a>.</p>
                </div>
            </div>
        </div>
    </div>
</section>