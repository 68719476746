<div class="education-course-banner-area jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="education-course-banner-content">
            <h1>A Course Is Essential For Building A Career</h1>
            <div class="btn-box">
                <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
            </div>
        </div>
    </div>
    <div class="education-categories-item technology">
        <div class="icon">
            <i class="bx bx-shape-triangle"></i>
        </div>
        <h3>
            <a routerLink="/courses-3-columns-style-1">Technology</a>
        </h3>
        <span>12 Courses</span>
    </div>
    <div class="education-categories-item marketing">
        <div class="icon">
            <i class="bx bx-target-lock"></i>
        </div>
        <h3>
            <a routerLink="/courses-3-columns-style-1">Marketing</a>
        </h3>
        <span>13 Courses</span>
    </div>
    <div class="education-categories-item business">
        <div class="icon">
            <i class="bx bx-briefcase-alt-2"></i>
        </div>
        <h3>
            <a routerLink="/courses-3-columns-style-1">Business</a>
        </h3>
        <span>10 Courses</span>
    </div>
    <div class="education-categories-item health">
        <div class="icon">
            <i class="bx bx-first-aid"></i>
        </div>
        <h3>
            <a routerLink="/courses-3-columns-style-1">Health</a>
        </h3>
        <span>6 Courses</span>
    </div>
    <div class="education-categories-item science">
        <div class="icon">
            <i class="bx bxs-drink"></i>
        </div>
        <h3>
            <a routerLink="/courses-3-columns-style-1">Science</a>
        </h3>
        <span>5 Courses</span>
    </div>
    <div class="education-categories-item graphics-design">
        <div class="icon">
            <i class="bx bx-layer"></i>
        </div>
        <h3>
            <a routerLink="/courses-3-columns-style-1">Graphics Design</a>
        </h3>
        <span>15 Courses</span>
    </div>
</div>