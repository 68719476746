<section class="gym-feedback-area bg-dark">
    <div class="container">
        <div class="gym-feedback-inner">
            <div class="section-title">
                <span class="sub-title">Testimonials</span>
                <h2>Our Students Feedback</h2>
            </div>
            <div class="feedback-slides-two">
                <owl-carousel-o [options]="feedbackSlidesTwo">
                    <ng-template carouselSlide>
                        <div class="single-gym-feedback-box">
                            <img src="assets/img/user1.jpg" alt="image">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <div class="title">
                                <h3>John Smith</h3>
                                <span>Python Developer</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="single-gym-feedback-box">
                            <img src="assets/img/user2.jpg" alt="image">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <div class="title">
                                <h3>Sarah Taylor</h3>
                                <span>OOP Developer</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="single-gym-feedback-box">
                            <img src="assets/img/user3.jpg" alt="image">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <div class="title">
                                <h3>James Anderson</h3>
                                <span>PHP Developer</span>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>